import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import Grid from '../components/grid';
import ProjectCard from '../components/project-card';
import styled from 'styled-components';
import PageHeading from '../components/page-heading';
// import ContactForm from "../components/contactForm";

const RecentProjects = styled.section`
	@media screen and (min-width: 768px) {
		margin-bottom: 40px;
	}
`;

const IndexPage = ({ data }) => {
	const projects = data.allContentfulProject.nodes;
	// const home = data.allContentfulHome.nodes;
	return (
		<Layout>
			<div>
				{/* <Blurb
					dangerouslySetInnerHTML={{
						__html: home[0].body?.childMarkdownRemark.html
					}}
				/> */}
				<RecentProjects>
					<PageHeading text='Featured work' />
					<Grid>
						{projects.map(project => (
							<ProjectCard project={project} key={project.slug} />
						))}
					</Grid>
				</RecentProjects>
				{/* <ContactForm /> */}
			</div>
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexQuery {
		allContentfulHome {
			nodes {
				body {
					childMarkdownRemark {
						html
					}
				}
			}
		}
		allContentfulProject(sort: { fields: [date], order: DESC }) {
			nodes {
				title
				date(formatString: "YYYY")
				id
				slug
				logo {
					file {
						url
					}
				}
				thumbnail {
					gatsbyImageData(aspectRatio: 1.5, placeholder: DOMINANT_COLOR, quality: 85)
				}
				inDevelopment
			}
		}
	}
`;
