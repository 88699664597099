import React from "react";
import styled from "styled-components";

const GridContainer = styled.section`
	display: grid;
	grid-template-columns: ${(props) => (props.small ? "1fr 1fr" : "1fr")};
	grid-row-gap: 32px;
	grid-column-gap: 40px;
	@media screen and (min-width: 768px) {
		grid-row-gap: 64px;
		grid-template-columns: ${(props) =>
			props.small ? "1fr 1fr 1fr 1fr 1fr 1fr" : "1fr 1fr 1fr"};
	}
`;

const Grid = ({ children, small }) => {
	return <GridContainer small={small}>{children}</GridContainer>;
};

export default Grid;
